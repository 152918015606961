.slick-track { 
    margin-left: 0; 
}

.slick-dots li button:before {
    color: grey;
}

.slick-dots li.slick-active button:before {
    color:black !important;
}



.modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.modal.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.modal img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.modal.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .slick-track { 
        margin-bottom: 40px;
    }
}