.grecaptcha-badge { 
    visibility: hidden;
}

.sticky {
    padding: 40px 120px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    transition: all ease-in 0.2s;
}

.stickyScrollBlack {
    padding: 20px 64px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all ease-in 0.4s;
}

.stickyScrollWhite {
    padding: 20px 64px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    transition: all ease-in 0.4s;
}

@media screen and (min-width: 1800px) {

    .sticky {
        padding: 56px 248px;
    }

    .stickyScroll {
        padding: 16px 248px
    }
}

@media screen and (max-width: 1300px) {

    .sticky {
        padding: 56px 88px;
    }

    .stickyScroll {
        padding: 16px 88px
    }
}

@media screen and (max-width: 1100px) {

    .sticky {
        padding: 56px 72px;
    }

    .stickyScroll {
        padding: 16px 72px
    }
}

@media screen and (max-width: 850px) {

    .sticky {
        padding: 56px 88px;
    }

    .stickyScroll {
        padding: 16px 88px
    }
}

.css-h4y409-MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
}